import React from 'react';

// Icons import
import LocationIcon from '../resources/location.svg';


function AboutUs() {
  return (
    <>
    <div className="card" id="Profile">
    <h1>About Us</h1>
    <p>Since 2011, CM Environmental Inc. has specialized in the removal or encapsulation of various hazardous materials, including asbestos, mould, and lead. We have a team of trained and certified professionals that make use of our leading industry equipment and techniques on the substance that you need removed. We are locally owned and operated, as well as fully licensed, insured, and AHERA certified. We at CM Environmental Inc. pride ourselves on providing you with a prompt, safe removal process.</p>
    </div>
    </>
  );
}


export default AboutUs;