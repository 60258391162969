import React from 'react';
import '../css/Blog.css';

const Blog = () => {
  return (
    <>
    <div className="card" id="Profile">
    <h1>Blog</h1>
    <p>Coming Soon...</p>
    </div>
    </>
  )
}

export default Blog