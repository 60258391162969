import React from 'react'

const Services = () => {
  return (
    <div className='card'>
        <div className='title-row-card'>
            <h1>Services</h1>
        </div>
        <div className='title-row-card'>
            <h5>Coming soon...</h5>
        </div>
    </div>
  )
}

export default Services