import React from 'react'

const Projects = () => {
  return (
    <div className='card'>
        <h1>Projects</h1>
        <p>Coming Soon...</p>
    </div>
  )
}

export default Projects